
import { Options, Vue } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { AuthController } from "@/app/ui/controllers/AuthController";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import UserProfileSection from "@/app/ui/views/account/user-profile-section.vue";
import DataAccountSection from "@/app/ui/views/account/data-account-section.vue";
import Logout from "@/app/ui/components/icons/modules/logout.vue";
import ModalChangePassword from "@/app/ui/views/account/modal-change-password.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import ModalChangeEmail from "@/app/ui/views/account/modal-change-email.vue";
import QRCodeProfile from "@/app/ui/components/qr-code-hub/index.vue";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    ModalChangeEmail,
    DataAccountSection,
    UserProfileSection,
    DetailLayout,
    Logout,
    ModalChangePassword,
    QRCodeProfile
  },
  computed: {
    getCurrentRoute() {
      return this.$route.name;
    }
  }
})
export default class Account extends Vue {
  get isSenderDashboard() {
    return IS_SENDER_ACCOUNT;
  }

  // data profile account
  fetchDataProfile() {
    return AccountController._getData();
  }
  get dataProfile() {
    return AccountController.accountData;
  }
  get isLoading() {
    return AuthController.isLoading;
  }
  get isErrorInternetOrSystem() {
    return AccountController.isError;
  }
  get isLoadingAccount() {
    return AccountController.isLoading;
  }
  get errorCause() {
    return AccountController.errorCause;
  }

  // logout
  isOpenModal = false;
  onOpenLogout() {
    this.isOpenModal = true;
  }
  onCloseLogout() {
    this.isOpenModal = false;
  }
  async onSubmitLogout() {
    this.isOpenModal = false;
    await AuthController.logout();
  }
  handleCloseError() {
    return AuthController.handleErrorLogout();
  }
  get errorCauseLogout() {
    return AuthController.errorCause;
  }
  get isErrorInternetOrSystemLogout() {
    return AuthController.isErrorLogout;
  }

  // modal change password
  modalChangePassword = false;
  isSenderChangePassword = false;
  openModalChangePassword() {
    if (this.isSenderDashboard) {
      this.isSenderChangePassword = true;
    } else {
      MainAppController.setSnackbar(false);
      this.modalChangePassword = true;
    }
  }
  get dialogData() {
    if (this.isSenderChangePassword) {
      return {
        title: this.$t("Ubah Password Melalui Aplikasi"),
        message: this.$t(
          "Silahkan ubah password Anda melalui aplikasi Lion Parcel"
        ),
        image: "account-pin-lock",
        textSuccess: this.$t("Tutup"),
        onSuccess: () => (this.isSenderChangePassword = false)
      };
    }
    return {
      title: this.$t("profile.logoutConfirmTitle"),
      message: this.$t(
        "Sebelum keluar, pastikan kembali tidak ada aktivitas yang sedang berjalan."
      ),
      image: this.$t("logout-image"),
      textClose: this.$t("Ya"),
      onClose: this.onSubmitLogout,
      textSuccess: this.$t("Tidak"),
      onSuccess: this.onCloseLogout
    };
  }

  // modal change email
  modalChangeEmail = false;
  openModalChangeEmail() {
    MainAppController.setSnackbar(false);
    this.modalChangeEmail = true;
  }

  //  download qr code
  get partnerDetail() {
    const partner = this.dataProfile.account_type_detail;
    return {
      id: partner.id,
      name: partner.company_name,
      address: partner.address,
      roleType: this.dataProfile.account_type.toLowerCase() === "partner"
    };
  }
  downloadQRCode() {
    const qrcodeProfile: any = this.$refs.qrcodeProfile;
    qrcodeProfile.download();
  }
}
