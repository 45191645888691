import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-6 overflow-hidden" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-y-6 pt-6 gap-x-3" }
const _hoisted_3 = { class: "my-1 flex item-start gap-x-4 flex-wrap" }
const _hoisted_4 = { class: "break-all" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_lp_button = _resolveComponent("lp-button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      class: "pt-1",
      label: "Profil User"
    }),
    _createVNode("div", _hoisted_2, [
      (!_ctx.isSenderDashboard)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 0,
            label: "User ID",
            value: _ctx.userProfile.account_id
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      (!_ctx.isSenderDashboard)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 1,
            label: "Username",
            value: _ctx.userProfile.username
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('Nama'),
        value: _ctx.userProfile.name
      }, null, 8, ["label", "value"]),
      (!_ctx.isSenderDashboard)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 2,
            class: "capitalize",
            label: "Role",
            value: _ctx.userProfile.role_type
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('No. Telepon'),
        value: _ctx.userProfile.phone_number
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, { label: "Email" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_3, [
            _createVNode("p", _hoisted_4, _toDisplayString(_ctx.userProfile.email), 1),
            (!_ctx.isSenderDashboard)
              ? (_openBlock(), _createBlock(_component_lp_button, {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change-email'))),
                  compact: "",
                  "text-color": "red-lp-100",
                  flat: "",
                  title: _ctx.$t('Ubah')
                }, null, 8, ["title"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ])
  ]))
}