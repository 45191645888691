
import { Options, prop, Vue } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { AuthController } from "@/app/ui/controllers/AuthController";
import debounce from "lodash/debounce";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  message = prop<string>({
    type: String,
    default: ""
  });
}

@Options({
  emits: ["update:modelValue"],
  components: {}
})
export default class ModalChangePassword extends Vue.with(Props) {
  toggleModalChangePassword(bool: boolean) {
    this.$emit("update:modelValue", bool);
  }

  // logic confirmation change password
  get isFormValid() {
    return (
      this.confirmationNewPassword &&
      this.newPassword &&
      this.confirmationNewPasswordInvalid === "" &&
      this.newPasswordInvalid === "" &&
      this.isOldPasswordValid
    );
  }
  confirmationChangePassword() {
    this.toggleModalChangePassword(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: this.$t("profile.changePassword.title"),
        message: this.$t(
          "Anda yakin ingin menyimpan perubahan password baru ?"
        ),
        textCancel: this.$t("Kembali"),
        textSuccess: this.$t("Simpan"),
        onSubmit: () => this.submitChangePassword(),
        onClose: () => {
          this.toggleModalChangePassword(true);
          MainAppController.closeMessageModal();
        },
        image: "are-you-sure"
      })
    );
  }
  async submitChangePassword() {
    MainAppController.closeMessageModal();
    const resp = await AuthController.editPassword({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    });
    if (resp) {
      this.newPassword = "";
      this.confirmationNewPassword = "";
      this.passwordType = "password";
      this.confirmationNewPasswordType = "password";
      this.openSnackbar();
    }
  }

  // snackbar success change password
  openSnackbar() {
    MainAppController.setSnackbarMessage(
      this.$t("Perubahan password berhasil !")
    );
    MainAppController.setSnackbar(true);
  }

  // input field password
  newPassword = "";
  passwordType = "password";
  showPassword() {
    if (this.passwordType === "password") {
      this.passwordType = "text";
    } else {
      this.passwordType = "password";
    }
  }
  get newPasswordInvalid() {
    if (this.newPassword === "") {
      return "";
    }
    if (this.newPassword.length < 8) {
      return this.$t("Password harus berisi minimal 8 karakter");
    }
    return "";
  }

  // input field confirmation password
  confirmationNewPassword = "";
  confirmationNewPasswordType = "password";
  showConfirmationPassword() {
    if (this.confirmationNewPasswordType === "password") {
      this.confirmationNewPasswordType = "text";
    } else {
      this.confirmationNewPasswordType = "password";
    }
  }
  get confirmationNewPasswordInvalid() {
    if (this.confirmationNewPassword === "") {
      return "";
    }
    if (this.confirmationNewPassword !== this.newPassword) {
      return this.$t("Password baru tidak sama");
    }
    return "";
  }

  // input field old password
  oldPassword = "";
  oldPasswordType = "password";
  showOldPassword() {
    if (this.oldPasswordType === "password") {
      this.oldPasswordType = "text";
    } else {
      this.oldPasswordType = "password";
    }
  }
  get isWrongOldPassword() {
    return AuthController.isErrorWrongPassword;
  }
  isOldPasswordValid = false;
  validateOldPassword = debounce(async (e: any) => {
    const value = e.target.value;
    if (value.length === 0) {
      AuthController.setErrorPassword(false);
      this.isOldPasswordValid = false;
      return;
    }
    this.isOldPasswordValid = await AuthController._onCheckPassword({
      email: AccountController.accountData.username,
      password: e.target.value
    });
  }, 500);
}
