
import { Vue, Options } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";

@Options({
  components: { Title }
})
export default class DataAccountSection extends Vue {
  typeCode = "";
  get userProfile() {
    const user = AccountController.accountData;
    if (user.account_type_detail.type === "pos") {
      this.typeCode = "POS Code";
    } else if (user.account_type_detail.type === "sub-console") {
      this.typeCode = "Sub-Consolidator Code";
    } else if (user.account_type_detail.type === "console") {
      this.typeCode = "Consolidator Code";
    } else {
      this.typeCode = "Client Code";
    }
    return AccountController.accountData;
  }

  get isSenderDashboard() {
    return IS_SENDER_ACCOUNT;
  }
}
