
import { Vue, prop } from "vue-class-component";
import qrcode from "qrcode";
import html2canvas from "html2canvas";
import {
  directDownloadFile,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "../../controllers/MainAppController";

class Props {
  partnerId = prop<number | string>({
    type: Number,
    default: 0
  });
  partnerName = prop<string>({
    type: String,
    default: ""
  });
  partnerAddress = prop<string>({
    type: String,
    default: ""
  });
}

export default class QRCodeHub extends Vue.with(Props) {
  mounted() {
    qrcode.toCanvas(
      document.getElementById("qr-code-hub-canvas"),
      `${this.partnerId}`,
      {
        margin: 0,
        width: 620
      }
    );
  }

  download() {
    MainAppController.showLoading();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    html2canvas(document.getElementById("qr-code-hub")!, {
      scale: 2,
      logging: false,
      onclone: element => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element.getElementById("qr-code-hub")!.style.display = "block";
      }
    })
      .then(data => {
        const image = data.toDataURL("image/png");
        directDownloadFile(image, `qr_code_hub_${this.partnerId}.png`);
        window.URL.revokeObjectURL(image);
      })
      .finally(() => MainAppController.closeLoading());
  }

  ellipsisString(val: string) {
    return ellipsisString(val, 20);
  }
}
