import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"width":"60vw"},
  class: "relative edit-body overflow-y-auto"
}
const _hoisted_2 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    "model-value": _ctx.modelValue,
    persistent: true,
    title: _ctx.$t('Ubah Email'),
    "onUpdate:modelValue": _ctx.toggleModalChangeEmail
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('Email Lama'),
          class: "mb-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_lp_input, {
              disabled: true,
              "disable-background": true,
              modelValue: _ctx.oldEmail,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.oldEmail = $event)),
              placeholder: "Masukan email baru",
              type: "email"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_DataWrapper, {
          label: _ctx.$t('Email Baru'),
          class: "mb-8"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_lp_input, {
              modelValue: _ctx.newEmail,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.newEmail = $event)),
              placeholder: _ctx.$t('Masukan email baru'),
              type: "email"
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_lp_button, {
            disabled: _ctx.errorFormEmail,
            "text-color": "white",
            "custom-class": "px-10",
            title: _ctx.$t('Simpan'),
            onClick: _ctx.confirmationChangeEmail
          }, null, 8, ["disabled", "title", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["model-value", "title", "onUpdate:modelValue"]))
}