
import { Vue, Options } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";

@Options({
  emits: ["change-email"],
  components: { Title }
})
export default class UserProfileSection extends Vue {
  get userProfile() {
    return AccountController.accountData;
  }

  get isSenderDashboard() {
    return IS_SENDER_ACCOUNT;
  }
}
