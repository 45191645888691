import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "px-6" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-y-6 pt-6 gap-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      class: "pt-1",
      label: _ctx.$t('Akun')
    }, null, 8, ["label"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        class: 
          _ctx.userProfile.account_type !== `internal`
            ? `capitalize`
            : `capitalize col-span-2`
        ,
        label: _ctx.$t('Tipe Akun'),
        value: _ctx.userProfile.account_type
      }, null, 8, ["class", "label", "value"]),
      (_ctx.userProfile.account_type !== `internal`)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 0,
            class: "capitalize",
            label: _ctx.typeCode,
            value: _ctx.userProfile.account_type_detail.code
          }, null, 8, ["label", "value"]))
        : _createCommentVNode("", true),
      (!_ctx.isSenderDashboard)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 1,
            class: "capitalize",
            label: `${_ctx.userProfile.account_type} ID`,
            value: 
          _ctx.userProfile.account_type_detail.id || _ctx.userProfile.account_id || '-'
        
          }, null, 8, ["label", "value"]))
        : _createCommentVNode("", true),
      (!_ctx.isSenderDashboard)
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 2,
            class: "capitalize",
            label: 
          _ctx.$t('Nama', {
            prefix:
              _ctx.userProfile.account_type_detail.countryCode.toLowerCase() !== 'id'
                ? `${_ctx.userProfile.account_type} `
                : '',
            suffix:
              _ctx.userProfile.account_type_detail.countryCode.toLowerCase() === 'id'
                ? ` ${_ctx.userProfile.account_type}`
                : ''
          })
        ,
            value: 
          _ctx.userProfile.account_type_detail.company_name ||
            _ctx.userProfile.name ||
            '-'
        
          }, null, 8, ["label", "value"]))
        : _createCommentVNode("", true),
      (_ctx.userProfile.account_type === 'partner')
        ? (_openBlock(), _createBlock(_Fragment, { key: 3 }, [
            (!_ctx.isSenderDashboard)
              ? (_openBlock(), _createBlock(_component_DataWrapper, {
                  key: 0,
                  class: "pr-2",
                  label: _ctx.$t('Alamat'),
                  value: 
            _ctx.userProfile.account_type_detail.partnerLocation.partner.address
          
                }, null, 8, ["label", "value"]))
              : _createCommentVNode("", true),
            (!_ctx.isSenderDashboard)
              ? (_openBlock(), _createBlock(_component_DataWrapper, {
                  key: 1,
                  label: `${_ctx.$t('Long')} / ${_ctx.$t('Lat')}`,
                  value: 
            `${_ctx.userProfile.account_type_detail.partnerLocation.partner.long} / ${_ctx.userProfile.account_type_detail.partnerLocation.partner.lat}`
          
                }, null, 8, ["label", "value"]))
              : _createCommentVNode("", true),
            (!_ctx.isSenderDashboard)
              ? (_openBlock(), _createBlock(_component_DataWrapper, {
                  key: 2,
                  label: _ctx.$t('No. Telepon'),
                  value: 
            _ctx.userProfile.account_type_detail.partnerLocation.partner
              .partner_phone_number
          
                }, null, 8, ["label", "value"]))
              : _createCommentVNode("", true),
            (!_ctx.isSenderDashboard)
              ? (_openBlock(), _createBlock(_component_DataWrapper, {
                  key: 3,
                  label: "Email",
                  value: 
            _ctx.userProfile.account_type_detail.partnerLocation.partner
              .partner_contact_email
          
                }, null, 8, ["value"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.userProfile.account_type_detail.hub?.hub_id)
        ? (_openBlock(), _createBlock(_Fragment, { key: 4 }, [
            (!_ctx.isSenderDashboard)
              ? (_openBlock(), _createBlock(_component_DataWrapper, {
                  key: 0,
                  class: "capitalize",
                  label: _ctx.$t('Kode Hub'),
                  value: _ctx.userProfile.account_type_detail.hub.hub_code
                }, null, 8, ["label", "value"]))
              : _createCommentVNode("", true),
            (!_ctx.isSenderDashboard)
              ? (_openBlock(), _createBlock(_component_DataWrapper, {
                  key: 1,
                  class: "capitalize",
                  label: _ctx.$t('Nama Hub'),
                  value: _ctx.userProfile.account_type_detail.hub.hub_name
                }, null, 8, ["label", "value"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}