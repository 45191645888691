<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M5 4.167c-.442 0-.866.175-1.179.488-.312.312-.488.736-.488 1.178v8.334c0 .442.176.866.488 1.178.313.313.737.488 1.179.488h3.333c.442 0 .866-.175 1.179-.488.312-.312.488-.736.488-1.178v-.834c0-.46.373-.833.833-.833.46 0 .834.373.834.833v.834c0 .884-.352 1.732-.977 2.357-.625.625-1.473.976-2.357.976H5c-.884 0-1.732-.351-2.357-.976-.625-.625-.976-1.473-.976-2.357V5.833c0-.884.35-1.732.976-2.357C3.268 2.851 4.116 2.5 5 2.5h3.333c.884 0 1.732.351 2.357.976.625.625.977 1.473.977 2.357v.834c0 .46-.373.833-.834.833-.46 0-.833-.373-.833-.833v-.834c0-.442-.176-.866-.488-1.178-.313-.313-.737-.488-1.179-.488H5zm8.577 1.91c.326-.325.853-.325 1.179 0l3.333 3.334c.326.325.326.853 0 1.178l-3.333 3.334c-.326.325-.853.325-1.179 0-.325-.326-.325-.854 0-1.179l1.911-1.91H5.833C5.373 10.833 5 10.46 5 10c0-.46.373-.833.833-.833h9.655l-1.91-1.911c-.326-.326-.326-.853 0-1.179z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
import { Vue } from "vue-class-component";

export default class Logout extends Vue {}
</script>
