
import { Options, prop, Vue } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  message = prop<string>({
    type: String,
    default: ""
  });
}

@Options({
  emits: ["update:modelValue", "success"],
  components: {}
})
export default class ModalChangeEmail extends Vue.with(Props) {
  toggleModalChangeEmail(bool: boolean) {
    this.$emit("update:modelValue", bool);
  }

  // confirmation change email
  confirmationChangeEmail() {
    this.toggleModalChangeEmail(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: this.$t("Konfirmasi Perubahan"),
        message: this.$t("Anda yakin ingin menyimpan perubahan detail Akun ?"),
        textCancel: this.$t("Kembali"),
        textSuccess: this.$t("Simpan"),
        onSubmit: () => this.submitChangeEmail(),
        onClose: () => {
          this.toggleModalChangeEmail(true);
          MainAppController.closeMessageModal();
        },
        image: "are-you-sure"
      })
    );
  }
  async submitChangeEmail() {
    MainAppController.closeMessageModal();
    const resp = await AccountController.editEmailAccount({
      email: this.newEmail
    });
    if (resp) {
      this.openSnackbar();
      this.$emit("success");
    }
  }

  // snackbar success change password
  openSnackbar() {
    MainAppController.setSnackbarMessage(this.$t("Perubahan email berhasil !"));
    MainAppController.setSnackbar(true);
  }

  // new email
  newEmail = "";
  get errorFormEmail() {
    return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.newEmail);
  }

  // old email
  get oldEmail() {
    return AccountController.accountData.email;
  }
}
